import quickFetch from 'quick-fetch';
try {
  (() => {
    const MARKETING_TASK_FAMILY = 'MARKETING';
    const TASK_FORM_READ_PROPERTIES = [
    // From https://git.hubteam.com/HubSpot/crm-tasks-ui/blob/66ee016e2001a4a8f9c49d2fd50b9e4714a4e9c5/crm-tasks-ui/static/js/new/constants/properties.ts#L40
    'hs_task_subject', 'hs_task_status', 'hs_task_type', 'hs_task_priority', 'hubspot_owner_id', 'hs_queue_membership_ids', 'hs_timestamp', 'hs_task_relative_reminders', 'hs_task_repeat_interval', 'hs_task_reminders', 'hs_task_body', 'hs_at_mentioned_owner_ids', 'hs_task_is_all_day', 'hs_all_accessible_team_ids',
    // This is used below to redirect to the Marketing Tasks app if necessary
    'hs_task_family'];
    const TASK_ID_REGEX = /\/task\/(\d+)/;
    function getMaybeTaskId() {
      const searchParams = new URLSearchParams(window.location.search);
      const taskIdFromSearch = searchParams.get('taskId');
      if (taskIdFromSearch) {
        return taskIdFromSearch;
      }
      if (TASK_ID_REGEX.test(window.location.pathname)) {
        var _TASK_ID_REGEX$exec;
        return ((_TASK_ID_REGEX$exec = TASK_ID_REGEX.exec(window.location.pathname)) === null || _TASK_ID_REGEX$exec === void 0 ? void 0 : _TASK_ID_REGEX$exec[1]) || null;
      }
      return null;
    }
    function getMarketingTasksPath(taskId) {
      return `/marketing/${quickFetch.getPortalId()}/tasks?viewTaskId=${taskId}`;
    }
    const taskId = getMaybeTaskId();
    if (taskId) {
      const query = TASK_FORM_READ_PROPERTIES.reduce((acc, property) => `${acc}&properties=${property}`, '');
      quickFetch.makeEarlyRequest(`single-task-${taskId}`, {
        url: quickFetch.getApiUrl(`/tasks/v2/tasks/${taskId}?${query}`, true),
        timeout: 15000,
        dataType: 'json',
        contentType: 'application/json',
        type: 'GET',
        whenFinished: response => {
          var _response$properties;
          if (response !== null && response !== void 0 && response.objectId && (response === null || response === void 0 || (_response$properties = response.properties) === null || _response$properties === void 0 || (_response$properties = _response$properties.hs_task_family) === null || _response$properties === void 0 ? void 0 : _response$properties.value) === MARKETING_TASK_FAMILY) {
            window.location.href = window.location.origin + getMarketingTasksPath(response.objectId);
          }
        }
      });
    }
  })();
} catch (e) {
  // Do nothing
}